<template>
    <div>
        <div
            v-if="!integrationsAvailable"
            class="pbottom-small"
        >
            <h4>{{ $t("no_integrations") }}</h4>
            <p>{{ $t("no_integrations_text") }}</p>
        </div>
        <div
            v-else
            class="integrations-container"
        >
            <IntegrationTile
                v-if="atlassianFeatureConfig.enabled"
                :title="$t('atlassian_cloud')"
                :url="atlassianFeatureConfig.config.instanceUrl"
                :icon="{type:'vendors',name:'atlassian-icon'}"
                :on-connect="startAtlassianUserConnectionFlow"
                :on-revoke="removeAtlassianUserConnection"
                :is-connected="isAtlassianIntegrationUserConnected"
                :is-loading="isUserConnectingToAtlassian || isFetchingAtlassianIntegrationStatus"
            />
            <IntegrationTile
                v-if="googleCalendarFeatureConfig.enabled"
                :title="$t('google_calendar')"
                :url="''"
                :icon="{type:'vendors',name:'google-icon'}"
                :on-connect="startGoogleCalendarUserConnectionFlow"
                :on-revoke="removeGoogleCalendarUserConnection"
                :is-connected="isGoogleCalendarIntegrationUserConnected"
                :is-loading="isUserConnectingToGoogleCalendar || isFetchingGoogleCalendarIntegrationStatus"
            />
        </div>
    </div>
</template>

<script>
import IntegrationTile from "@web/views/user-options/IntegrationTile";
import {
    atlassianConnectService,
    googleCalendarConnectService,
} from "@web/services";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import {
    mapActions,
    mapState,
} from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { ATLASSIAN_MODULE_NAME } from "@web/store/atlassian/atlassian";
import { FETCH_USER_CONNECTION_STATUS } from "@web/store/atlassian/actions";
import { GOOGLE_CALENDAR_MODULE_NAME } from "@web/store/google/google-calendar";

export default {
    name: "IntegrationSettings",
    components: { IntegrationTile },
    data() {
        return {
            isUserConnectingToAtlassian: false,
            isUserConnectingToGoogleCalendar: false,
        };
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, ["intranet"]),
        ...mapState({
            isFetchingAtlassianIntegrationStatus: state => state.atlassian.isFetching,
            isAtlassianIntegrationUserConnected: state => state.atlassian.isUserConnected,
            isFetchingGoogleCalendarIntegrationStatus: state => state.googleCalendar.isFetching,
            isGoogleCalendarIntegrationUserConnected: state => state.googleCalendar.isUserConnected,
        }),
        atlassianFeatureConfig() {
            return this.intranet.flags["atlassian-3lo"];
        },
        googleCalendarFeatureConfig() {
            return this.intranet.flags["google-calendar"];
        },
        integrationsAvailable() {
            return this.atlassianFeatureConfig.enabled || this.googleCalendarFeatureConfig.enabled;
        },
    },
    methods: {
        ...mapActions({
            fetchAtlassianStatus: ATLASSIAN_MODULE_NAME + FETCH_USER_CONNECTION_STATUS,
            fetchGoogleCalendarStatus: GOOGLE_CALENDAR_MODULE_NAME + FETCH_USER_CONNECTION_STATUS,
        }),
        openModal(name) {
            this.$modal.show(name);
        },
        async startAtlassianUserConnectionFlow() {
            this.isUserConnectingToAtlassian = true;
            const popup = await atlassianConnectService.startUserConnectionFlow(this.intranet.uid);
            const channel = new BroadcastChannel("connect-atlassian-user");
            channel.onmessage = ({ data }) => {
                const msg = data.data;
                this.fetchAtlassianStatus();
                this.isUserConnectingToAtlassian = false;
                analytics.log(getGlobalConfiguration().analytics_event_name_atl_cloud_connected_by_user);
                popup.close();
                this.$notify({
                    group: "app",
                    type: msg.includes("success") ? "success" : "error",
                    text: this.$t(msg),
                    duration: 8000,
                });
                channel.close();
            };
        },

        async removeAtlassianUserConnection() {
            await atlassianConnectService.removeUserConnection(this.intranet.uid);
            this.fetchAtlassianStatus();
        },
        async startGoogleCalendarUserConnectionFlow() {
            this.isUserConnectingToGoogleCalendar = true;
            try {
                await googleCalendarConnectService.startUserConnectionFlow(this.intranet.uid);
                this.fetchGoogleCalendarStatus();
                this.$notify({
                    group: "app",
                    type: "success",
                    text: this.$t("connect_with_google_calendar_connection_success"),
                    duration: 8000,
                });
            } catch (e) {
                this.$notify({
                    group: "app",
                    type: "error",
                    text: this.$t("connect_with_google_calendar_err_declined"),
                    duration: 8000,
                });
            } finally {
                this.isUserConnectingToGoogleCalendar = false;
            }
        },

        async removeGoogleCalendarUserConnection() {
            await googleCalendarConnectService.removeUserConnection(this.intranet.uid);
            this.fetchGoogleCalendarStatus();
        },

    },
    mounted() {
        this.fetchAtlassianStatus();
        this.fetchGoogleCalendarStatus();
    },
};
</script>

<style lang="scss" scoped>
.integrations-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 -0.5rem;

    .integration-tile {
        flex: 1 1 calc(50% - 1rem);
        width: 200px;
        margin: 0.5rem;
    }
}

.icon-container {
    cursor: pointer;
}
</style>
