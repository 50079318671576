<template>
    <article class="card padding-small integration-tile">
        <LoadingWrapper :is-loading="isLoading">
            <header class="centered">
                <Icon
                    class="large"
                    :type="icon.type"
                    :name="icon.name"
                />
                <div class="bold">
                    {{ title }}
                </div>
            </header>
            <div class="content centered">
                <p class="meta">
                    {{ url }}
                </p>
            </div>
            <footer class="centered">
                <app-button
                    v-if="!isConnected"
                    @click="onConnect"
                >
                    {{ $t("integration_settings_connect") }}
                </app-button>
                <app-button
                    v-else
                    id="revoke-atlassian-access-btn"
                    @click="onRevoke"
                >
                    {{ $t("integration_settings_revoke_access") }}
                </app-button>
            </footer>
        </LoadingWrapper>
    </article>
</template>

<script>
export default {
    name: "IntegrationTile",

    props: {
        icon: {
            required: true,
            type: Object

        },
        title: {
            required: true,
            type: String
        },
        url: {
            required: true,
            type: String
        },
        onConnect: {
            required: true,
            type: Function
        },
        onRevoke: {
            required: true,
            type: Function
        },
        isLoading: {
            required: true,
            type: Boolean
        },
        isConnected: {
            required: true,
            type: Boolean
        }
    },
};
</script>

<style lang="scss" scoped>
    .integration-tile {
        max-width: 240px;
        header {
            flex: 0 0 auto;
            .icon {
                margin: 0 auto 0.5rem;
            }
        }
        .content {
            flex: 1 0 auto;
        }
        footer {
            flex: 0 0 auto;
        }

        #revoke-atlassian-access-btn {
            background-color: var(--background, #{$white});
            border: 2px solid rgb(68, 68, 68);
            color: rgb(68, 68, 68);
        }

        &::v-deep {
            .loader {
                width: 100%;
                height: 100%;
            }
            .loaded-content {
                height: 100%;
                display: flex;
                flex-direction: column;
            }
        }
    }

</style>
